<template>
  <div>
    <div v-if="!edit">
      <v-btn v-if="boxOffice && boxOffice.stripeId" color="#2285ce" href="https://dashboard.stripe.com/" target="_blank">
        <v-img class="mr-3" src="/stripe2.png" height="25px" width="18px"/>
        <span>Already Connected</span>
      </v-btn>
      <span v-else>Not connected</span>
    </div>
    <div v-else>
      <div v-if="boxOffice && boxOffice.stripeId">
        <v-label v-if="label">You have connected your stripe account and can take payments.
          <tooltip top>
            Happy Tickets doesn't ever handle your money, it goes direct from your customer <br/>
            to your stripe account where you can access it within days.<br/>
            We will never take money from your Stripe account, only pay it in.
          </tooltip>
        </v-label>
        <div>
          <v-btn color="error" @click="disconnectStripe()">
            <v-img class="mr-3 mt-n1" src="/stripe2.png" height="25px" width="18px"/>
            <span class="mt-n1">Disconnect Stripe</span>
          </v-btn>
        </div>
      </div>
      <div v-else>
        <v-label v-if="label">To take payments, you must connect to a stripe account.
          <tooltip top>
            <p>In order to charge for tickets, you need to set up a stripe account or link to an existing one.<br/>
            Happy Tickets doesn't ever handle your money, it goes direct from your customer <br/>
            to your stripe account where you can access it within days.</p>
            <p>Setting up an account only takes a few minutes.  We will never take money from your Strip<br/>e account, only pay it in.</p>
            <p>If you are not a "business", don't worry, any bank account will work perfectly.   If don't have your own website,
            <br/>you can use the your Box Office homepage: {{utils.makeBoxOfficeURL(boxOffice)}} as your "stripe website".</p>
            <p>If you already have a Strip account, you can connect to it using the button in the top right corner of the login screen<br/>
            <img src="/stripehelp.png" width="300"/></p>
          </tooltip>
        </v-label>
        <div>
          <v-btn color="#2285ce" @click="connectStripe">
            <v-img class="mr-3 mt-n1" src="/stripe2.png" height="25px" width="18px"/>
            <span class="mt-n1">Connect to Stripe</span>
          </v-btn>
        </div>
      </div>
    </div>

    <v-dialog v-model="showStripeToggle" persistent max-width="600px">
      <v-card>
        <v-card-title>Stripe is Connected             
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            Congratuations, you have connected to Stripe and now start charging for tickets.
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="close">Close</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import Store from '@/services/Store.js';

  export default {

    components: {
      Tooltip: () => import('./components/Tooltip'),
    },  
    
    computed: {
      utils: function () {
        return Utils;
      }
    },

    data () {
      return {
        showStripeToggle: false,
        boxOffice: null,
        uri: null,
      }
    },

    props: {
      value: null,
      edit: {
        type: Boolean,
        default: false,
      },
      label: {
        type: Boolean,
        default: false,
      },
    },

    watch: {
      value: function () {
        this.boxOffice = this.value;
      }
    },

    created() {
      this.boxOffice = this.value;
      let uri = window.location.search.substring(1); 
      let params = new URLSearchParams(uri);
      let code = params.get("code");
      var id = params.get("state");
      if (code && id) { 
        this.connectedStripe(id, code);
        this.showStripeToggle = true; 
      }
    },
    
    methods: {

      connectStripe() {
        this.$emit('clicked');
        var redirect = window.location.href;
        redirect = redirect.replace("127.0.0.1", "localhost");
        redirect = redirect.replace("\/"+this.boxOffice.id, "");
        var url = "https://connect.stripe.com/oauth/authorize?client_id=";
        url += process.env.VUE_APP_STRIPE_CONNECT_CLIENT_ID;
        url += "&state=" + this.boxOffice.id;
        url += "&redirect_uri=" + redirect;
        url += "&scope=read_write&response_type=code";
        window.location = url;
      },

      async connectedStripe(id, code) {
        var payload = {}
        payload.id = id;
        payload.code = code;
        this.boxOffice = await Api.post(this, "BoxOffice", "connectStripe", payload);
        Store.boxOffice.name = this.boxOffice.name;
        Store.boxOffice.id = this.boxOffice.id;
      },

      close() {
        this.showStripeToggle = false; 
        this.$emit('connected', this.boxOffice);
        this.$router.push(window.location.pathname + "/" + this.boxOffice.id);
      },

      async disconnectStripe() {
        this.boxOffice = await Api.post(this, "BoxOffice", "disconnectStripe", this.boxOffice);
        this.$emit('input', this.boxOffice)
      },
    },
  }

</script>
